.NavigationMenuRoot {
  align-items: center;
  width: 100% !important;
  position: fixed !important;
  background-color: white;
  align-items: center;
}

.header_logo {
  position: absolute;
  left: 10px;
  width: 180px;
  height: auto;
  margin: 1%;
}

.NavigationMenuList--top {
  padding-top: 3%;
  padding-bottom: 3%;
}

.NavigationMenuTrigger--variant {
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
  color: $bleu-1;
  font-weight: 700;
  border: none;
  padding: 8%;
  margin: 0;
  text-align: left;
  color: $rose !important;
  font-weight: 700 !important;
  display: flex !important;
  flex-direction: row !important;
  width: 40ch;
  margin: 0;
}

.ListSubMenu {
  padding: 3% 7%;
  max-width: 50ch;
}

.caretdown {
  position: "relative";
  top: 1;
  transition: transform 250ms ease;
}

[data-state="open"] > .caretdown {
  transform: rotate(-180deg);
}

.icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .icon {
    display: block;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .NavigationMenuRoot {
    display: flex;
    flex-direction: column;
  }
  .NavigationMenuList {
    display: flex;
    flex-direction: column;
    display: none;
  }
  .visible {
    display: block !important;
  }
  .hide {
    display: none !important;
  }
  .header_logo {
    display: flex;
    position: relative;
    left: 10px;
    top: 2px;
    width: 180px;
    height: auto;
    margin: 1%;
  }
}
