.article {
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.article__leftpart {
  width: 50%;
  h2 {
    color: $bleu-1;
    margin-bottom: 5%;
    text-transform: uppercase;
    font-size: 22px;
  }
  p {
    width: 60ch;
    font-size: 17px;
  }
  a {
    margin-top: 5%;
    text-transform: none;
    text-decoration: none;
    color: white;
    background-color: $bleu-1;
    padding: 1% 4%;
    border-radius: 15px;
  }
}

.article__rightpart {
  margin: auto;
  h3 {
    color: $bleu-1;
    text-align: center;
    margin-bottom: 4%;
  }
  img {
    width: 100%;
    max-width: 700px;
    margin: auto;
  }
}

@media screen and (max-width: 1000px) {
  .article {
    flex-direction: column;
  }

  .article__leftpart {
    width: 90%;
    h2 {
      font-size: 20px;
      max-width: 30ch;
      margin: auto;
      margin-bottom: 5%;
      text-align: center;
    }
    p {
      margin: auto;
      max-width: 40ch;
      font-size: 14px;
    }
    a {
      display: block;
      width: fit-content;
      margin: auto;
      text-align: center;
      background-color: $bleu-1;
    }
  }

  .article__rightpart {
    margin: auto;
    margin-top: 10%;
    h3 {
      color: $bleu-1;
      text-align: center;
      margin: auto;
      margin-bottom: 4%;
    }
    img {
      margin: 0% auto;
      max-width: 90%;
      display: flex;
    }
  }
}
