.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  img {
    height: 230px;
    width: 230px;
    border-radius: 30px;
    object-fit: cover;
    align-items: center;
  }
  h3 {
    margin-top: 5%;
    text-align: center;
    color: $bleu-1;
  }
  p {
    margin-top: 5%;
    max-width: 280px;
    text-align: center;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  transition: background-color 0.8s ease;
  border-radius: 20px;

  &:hover {
    h3 {
      color: white;
    }
    img {
      opacity: 0.6;
    }
    background-color: $bleu-1;
    cursor: pointer;
    color: white;
  }
}

.card-container {
  position: relative;
  display: inline-block;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $rose;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.values {
  margin-bottom: 5%;
  margin-top: 5%;
  h1 {
    margin-bottom: 5%;
  }
}

/* Pop up */

.popup {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.185);
  transition: all 0.3s ease-in-out;
  h1 {
    text-align: center;
  }
  h2 {
    text-align: center;
    font-family: $title;
    color: $bleu-1;
    margin-top: 3%;
  }
  img {
    width: 100%;
    min-height: 100vh;
    object-fit: contain;
  }
  p {
    max-width: 60ch;
    margin: auto;
  }
}

.popup-resume-container {
  position: relative;
  z-index: 3;
  margin: auto;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 50%;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  max-height: 65%;
  overflow: auto;
}

@media screen and (max-width: 1000px) {
  .card {
    margin-top: 5%;
  }
  .popup-resume-container {
    left: 40%;
    width: 80%;
  }
}
