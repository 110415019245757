.question {
  margin-top: 7%;
  text-align: center;
  h1 {
    font-size: 60px;
    padding: 20% 0%;
    border-radius: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../public/assets/Professionnel/Prospect.png");
    float: center;
  }
}

.advantages {
  margin-top: 7%;
  margin-bottom: 7%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  p {
    width: 20%;
    font-size: 15px;
  }
  .advantage {
    opacity: 1;
    color: white;
    padding: 1.5%;
    border-radius: 20px;
    span {
      font-size: 20px;
    }
  }
  .bleu {
    background-color: $bleu-1;
  }
}

.salesman {
  width: 60%;
  display: flex;
  margin: auto;
}

.contact {
  font-size: 20px;
  text-align: center;
  width: 80%;
  max-width: 80ch;
  margin: 5% auto;
}

.gammepro {
  margin-top: 7%;
  text-align: center;
  h1 {
    font-size: 60px;
    padding: 20% 0%;
    border-radius: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../public/assets/Professionnel/Avantages_vendeurs\ 1.png");
    float: center;
    margin-bottom: 7%;
  }
  p {
    font-size: 20px;
    max-width: 80ch;
    margin: auto;
  }
}

@media screen and (max-width: 767px) {
  .advantages {
    flex-direction: column;
    p {
      width: 90%;
      margin-top: 5%;
    }
    .advantage {
      padding: 3%;
      border-radius: 15px;
    }
  }
  .gammepro {
    h1 {
      font-size: 40px;
    }
  }
  .question {
    h1 {
      font-size: 30px;
    }
  }
}
