.hero {
  margin-top: 6%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .title {
    font-size: 60px;
    span {
      background-image: url("../../assets/Title/cercle-2.png");
      background-repeat: no-repeat;
      background-position: center;
      padding: 2%;
    }
  }
  img {
    width: 50%;
    border: 20px white solid;
    box-shadow: -2px 2px 2px #ccc;
    transform: rotate(5deg);
    object-fit: cover;
  }
  .left-part {
    .subtitle {
      margin-top: 5%;
      font-size: 30px;
    }
    p {
      margin-top: 5%;
      font-size: 18px;
      max-width: 60ch;
    }
    max-width: 90ch;
    line-height: 1.25;
  }
}

.about-us {
  margin-top: 5%;
  margin-bottom: 3%;
  h1 {
    font-size: 30px;
  }
}

.about-us__container {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  align-items: center;
  p {
    max-width: 60ch;
    border-right: 1px solid black;
    border-radius: 30px;
    padding: 3%;
    margin-left: 5%;
  }
}

.about-us__img {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  .img__align {
    display: flex;
    flex-direction: column;
  }
}

.img__align_1,
.img__align_2 {
  width: 24.6vw;
  height: 29.9vh;
  object-fit: cover;
  border-radius: 30px;
  margin: 3%;
}

.img__align_3 {
  width: 25vw;
  height: 58vh;
  object-fit: cover;
  border-radius: 30px;
  margin: 6%;
}

.causes {
  margin-bottom: 5%;
  margin-top: 5%;
  h1 {
    margin-bottom: 5%;
    font-size: 30px;
  }
}

.values {
  h1 {
    font-size: 30px;
  }
}

/* banner */

.partners {
  margin-top: 5%;
  h1 {
    text-transform: uppercase;
    font-size: 30px;
  }
  p {
    margin-top: 2%;
    font-size: 20px;
  }
  h2 {
    text-transform: uppercase;
    color: $bleu-1;
    font-size: 25px;
    margin-top: 1%;
    width: fit-content;
  }
  hr {
    width: 20%;
    height: 3px;
    margin-top: 4%;
  }
  h3 {
    text-align: center;
    text-transform: uppercase;
    color: $bleu-1;
    font-size: 30px;
    margin-top: 2%;
  }
}

@media screen and (max-width: 1000px) {
  .main {
    h1 {
      font-size: 23px;
      text-align: center;
    }
    p {
      font-size: 14px;
      text-align: center;
    }
  }
  .hero {
    flex-direction: column;
    .title {
      font-size: 30px;
      span {
        background: none;
      }
    }
    img {
      width: 90%;
      margin-top: 5%;
      margin-bottom: 8%;
      margin-left: auto;
      margin-right: auto;
    }
    .left-part {
      .subtitle {
        font-size: 20px;
        margin-bottom: 3%;
      }
      p {
        margin-bottom: 5%;
        font-size: 16px;
        margin: auto;
      }
    }
  }

  .about-us__container {
    flex-direction: column;
    p {
      margin-top: 3%;
      margin-bottom: 3%;
      max-width: 60ch;
      text-align: center;
    }
  }

  .about-us__img {
    flex-direction: column;
  }

  .img__align_1,
  .img__align_2,
  .img__align_3 {
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
    margin: 3%;
  }

  .partners {
    h1 {
      font-size: 25px;
    }
    p {
      font-size: 15px;
    }
    h2 {
      font-size: 20px;
      margin-top: 1%;
      margin-bottom: 10%;
      width: fit-content;
    }
    hr {
      width: 20%;
      height: 3px;
      margin-top: 4%;
    }
    h3 {
      text-align: center;
      font-size: 18px;
      margin-top: 4%;
      margin-bottom: 2%;
    }
    .fournisseurs,
    .to,
    .nationaux,
    .regionaux,
    .transports,
    .croisieres,
    .autocaristes {
      img {
        margin: 2%;
        width: 10%;
        min-width: 100px;
        height: auto;
      }
    }
  }
}
