.departments {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.department {
  width: 30%;
  min-width: 250px;
  background-color: white;
  border-radius: 10px;
  padding: 2%;
  margin-top: 5%;
  text-align: center;
  align-items: center;

  .icone {
    width: 90%;
    margin: auto;
    height: 15vw;
    border-radius: 10px;
    object-fit: cover;
  }
  .picto {
    width: 5%;
    vertical-align: middle;
    margin-right: 1%;
  }
  h2 {
    color: $bleu-1;
    margin: 4% auto;
    max-width: 25ch;
    font-size: 18px;
  }
  a {
    color: black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.department--contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .department {
    .icone {
      height: 30vw;
    }
    h2 {
      font-size: 15px;
    }
  }
}
